.registration-datetime {
    padding: 0;
    margin: 20px 0 0;
    opacity: 1;
    color: var(--ion-color-primary);
}

p {
    padding: 0;
    margin: 30px 0 10px;
}

h2 {
    padding: 0;
}

.registration-container {
    width: 100%;
}

.info {
    display: block;
    padding: 0 10px;
}

.register-form {
    margin-top: 20px;
    padding: 0 10px;
}

.register-form  ion-item::part(native) {
    padding: 0;
    margin-bottom: 20px;
  }

  .register-form ion-select {
      width: 100%;
  }
