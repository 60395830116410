.deactivated__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
  padding: 20px;
}

.deactivated__header {
  text-align: center;
  margin-bottom: 20px;
}
