.voting-container {
    position: relative;
    min-height: calc(100vh - 96px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px 0 0;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: var(--ion-background-color-deep);
}

.voting-card-wrapper {
    position: relative;
    z-index: 5;
    min-width: 50%;
}


.background__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% center;
}

.background__video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.background__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% center;
}

.background__video--black {
    display: none;
}

body.dark .background__video--black {
    display: block;
}

body.dark .background__video--white {
    display: none;
}

@media (prefers-color-scheme: dark) {
    .background__video--black {
        display: block;
    }

    .background__video--white {
        display: none;
    }

    body.light .background__video--black {
        display: none;
    }

    body.light .background__video--white {
        display: block;
    }
}

.background__logo {
    position: absolute;
    top: 2px;
    right: 20px;
    opacity: 0.4;
}

.robovoice__name {
    font-family: "CoFoSansPixel";
}
.robovoice__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    cursor: pointer;
}
ion-toggle.robovoice__toggle {
    height: 14px;
    width: 26px;
    --handle-height:  10px;
    --handle-width: 10px;
    margin-left: 6px;
}

.waiting-block {
    max-width: 600px;

}

.waiting-block * {
    text-align: center;
}

.voting-container ion-item,
.voting-container ion-list-header {
    --background: var(--ion-card-background);
}

