.login__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
  padding: 20px;
}

.login__header {
  text-align: center;
  margin-bottom: 20px;
}

.login__google-icon {
  margin-right: 10px;
}

.login__buttons-wrapper {
  text-align: center;
}


.login__buttons-wrapper ion-button {
  margin: 10px;
}

.login__modal {
  padding: 20px;
}

.login__modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login__checkbox-wrapper {
  display: flex;
  margin: 20px 0 40px;
}

.login__checkbox-wrapper ion-label {
  max-width: 80%;
  margin-left: 10px;
}
