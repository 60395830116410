.voting-block * {
    text-align: center;
}

.voting-instruction {
    padding-bottom: 20px;
}

.projects-list {
    display: block;
}

.projects-list * {
    text-align: left;
}

.projects-list .first-of-projects {
    margin-right: 10px;
}

.voting-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .voting-buttons {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.voting-stepper {
    margin-bottom: 10px;
}

.voting-stepper * {
    vertical-align: middle;

}

