.meet-datetime {
    padding: 0;
    margin: 20px 0 0;
    opacity: 1;
    color: var(--ion-color-primary);
}

p {
    padding: 0;
    margin: 30px 0 10px;
}

h2 {
    padding: 0;
}

.meet-container {
    width: 100%;
}

.info {
    display: block;
    padding: 0 10px;
}

.image-header {
    height: 150px;
    width: 100%;
    object-fit: cover;
    background-color: var(--ion-background);
    border: 0;
    outline: none;
}

.meet-interactive {
    margin-top: 20px;
}

.ticket-wrapper {
    text-align: center;
}

.ticket {
    font-family: 'CoFoSansPixel';
    border-radius: 0;
    width: auto;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    width: 260px;
    text-transform: uppercase;
}

.ticket-inner-wrapper {
    display: inline-block;
    padding: 20px 10px;
    background: #ffffff;
    color: #000000;
}

.ticket-qrcode-wrapper {
    display: inline-block;
    border: 2px dashed #000000;
    padding: 10px;
    background: #ffffff;
    width: auto;
    margin: 10px auto;
}

.ticket-header {
    text-align: left;
    padding: 10px 6px;
    display: inline-block;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.ticket-info {
    padding: 0 6px 10px;
    text-align: left;
    font-size: 12px;
    word-wrap: break-word;
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}

.ticket-content-wrapper {}

.voting-info {
    margin-top: 20px;
}
