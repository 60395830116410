.voting-block * {
    text-align: center;
}

.voting-instruction {
    padding-bottom: 20px;
}

.projects-list {
    display: block;
}

.projects-list * {
    text-align: left;
}

.projects-list ion-list-header ion-label {
    margin-top: 10px;
}

.to-be-continued {
    padding-top: 20px;
}

.results .projects-list ion-label {
    text-overflow: initial;
    white-space: normal;
    overflow: visible;
}

.winner {
    margin: 10px 0;
}

.winner h1 {
    color: var(--ion-text-color, #000);
    margin: 10px 0 10px;
}

