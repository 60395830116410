.meets {
    position: relative;
}

.meets-datetime {
    padding-left: 0;
    color: var(--ion-color-primary);
    opacity: 1;
}

.chips {
    margin-top: 20px;
}

.zeroscreen {
    padding: 20px;
    text-align: center;
    width: 100%;
}

.meet-card-image {
    background-color: var(--ion-card-background);
    height: 300px;
    object-fit: cover;
}

.refresher-active {
    z-index: 5;
}

.tabs {
    padding: 20px 20px 0;
    width: 100%;
}

.meets-title {
    margin: 20px 15px -10px 30px;
    padding: 0 10px 15px 10px;
    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.3);
    vertical-align: middle;
}

.meets-title-icon {
    margin: 0 10px 3px 0;
    vertical-align: bottom;
}

.meets-timeline {
    position: absolute;
    width: 1px;
    height: calc(100% - 100px);
    opacity: 0.3;
    background: var(--ion-color-medium);
    left: 40px;
}
