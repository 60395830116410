@font-face {
  font-family: 'CoFoSansPixel';
  src: url('../ui-kit/fonts/CoFoSansPixel/CoFoSansPixel.eot');
  src: url('../ui-kit/fonts/CoFoSansPixel/CoFoSansPixel.eot')
      format('embedded-opentype'),
    url('../ui-kit/fonts/CoFoSansPixel/CoFoSansPixel.woff2') format('woff2'),
    url('../ui-kit/fonts/CoFoSansPixel/CoFoSansPixel.woff') format('woff'),
    url('../ui-kit/fonts/CoFoSansPixel/CoFoSansPixel.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'CoFoSans';
  src: url('../ui-kit/fonts/CoFoSans-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

html.ios {
    --ion-default-font: "CoFoSans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

